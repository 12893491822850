.raffe {
  padding-bottom: 0px;
}

.row-container .raffe-image {
  width: 45%;
}

.row-container .raffe-info {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row-container .raffe-info a {
  margin: 0;
}

@media (max-width: 950px) {

  .raffe {
    flex-direction: column;
    padding-bottom: 30px;
  }

  .row-container .raffe-image {
    width: 100%;
  }

  .row-container .raffe-info {
    width: 100%;
  }

}
