.historia-img {
  width: 50%;
  margin: auto;
}

@media (max-width: 950px) {

  .historia-img {
    width: 100%;
  }

}
