.background-image-friends {
  height: 200px;
  width: auto;
}

.show-grid-car {
  background-color: #c3ffb2;
}

.profile-pic {
  width: 300px;
}

.person-wrapper {
  padding: 50px;
}

.caracter-row {
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.caracter h3{
  text-align: center;
}
