.column-container .privat-image {
  width: 30%;
  margin: auto;
}

@media (max-width: 950px) {

  .column-container .privat-image {
    width: 100%;
  }

}
