.column-container .fragor-image {
  width: 30%;
  margin: auto;
}

.column-container .fragor-info {
  max-width: 800px;
  margin: auto;
}

@media (max-width: 950px) {

  .column-container .fragor-image {
    width: 100%;
  }

}
