.column-container .line {
  width: 90%;
  margin: 80px auto;
}

.lang a {
  text-align: center;
}

.column-container .language-info a {
  text-align: left;
  display: block;
  text-decoration: underline;
}
