.column-container .bioteater-image{
  width: 55%;
  align-self: center;
}

.column-container .bioteater-info a {
  display: inline;
  font-size: 20px;
}

.column-container .bioteater-info .bioteater-slut {
  display: flex;
  justify-content: space-between;
}

.column-container .bioteater-info .bioteater-slut .bioteater-slut-image {
  width: 40%;
  margin-top: auto;
  margin-bottom: auto;
}

.column-container .bioteater-info .bioteater-slut .bioteater-slut-info {
  width: 55%;
}

@media (max-width: 950px) {

  .column-container .bioteater-image{
    width: 100%;
  }

  .column-container .bioteater-info .bioteater-slut {
    flex-direction: column;
  }

  .column-container .bioteater-info .bioteater-slut .bioteater-slut-image {
    width: 100%;
  }

  .column-container .bioteater-info .bioteater-slut .bioteater-slut-info {
    width: 100%;
  }

}
