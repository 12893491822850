body, html{
  height: calc(100% - 80px);
  padding: 0;
  margin: 0;
}

.root {
  width: 100%;
}

.main {
  height: 100%;
  margin-top: 80px;
  margin-bottom: 89px;
  padding-bottom: 89px;
}

.app {
  height: 100%;
}

h2 {
  font-weight: bold;
  color: green;
}

h3 {
  color: green;
  font-weight: bold;
  font-size: 28px;
  text-align: left;
  margin-top: 7px;
}

h4 {
  text-align: left;
  font-weight: bold;
  margin: 20px 0 5px 0;
}

p {
  text-align: left;
  margin: 10px 0;
}

.bold {
  font-weight: bold;
}

.compact p{
  margin: 0;
}

a {
  color: green;
  font-weight: bold;
  text-decoration: underline;
  display: block;
}

a:hover {
  color: #013d01;
}

.link-page {
  display: inline;
}

.line {
  height: 1px;
  background-color: green;
  width: 90%;
  margin: 30px 0;
}

.column-container {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: auto;
  padding: 30px 0;
  max-width: 1000px;
}

.column-big-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px 0;
}

.row-container {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: auto;
  padding: 30px 0;
}

.row-big-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

@media (max-width: 950px) {
  .main {
    margin-bottom: 0;
  }
}
