.column-big-container .play-image {
  margin: auto;
}

.column-big-container .play-info {
  width: 60%;
}

@media (max-width: 950px) {

  .column-big-container .play-info {
    width: 80%;
  }

}
