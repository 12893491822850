.column-container .ovriga-image {
  width: 40%;
  margin: auto;
}

@media (max-width: 950px) {

  .column-container .ovriga-image {
    width: 100%;
  }

}
