.alg {
  align-items: center;
  justify-content: center;
}

.row-container .kontakt-image {
  width: 30%;
  display: flex;
  justify-content: center;
}

.row-container .kontakt-info {
  width: 40%;
}

@media (max-width: 950px) {

  .alg {
    flex-direction: column;
  }

  .row-container .kontakt-image {
    width: 100%;
  }

  .row-container .kontakt-info {
    width: 100%;
  }
}
