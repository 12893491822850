
.row-big-container .musikforlag-image {
  width: 40%;
}

.row-big-container .musikforlag-info {
  width: 40%;
}

#musik-logga {
  width: 130px;
  padding: 10px 0;
}

@media (max-width: 950px) {

  .musikforlag {
    flex-direction: column;
    width: 80%;
    margin: auto;
    padding: 30px 0;
  }

  .row-big-container .musikforlag-image {
    width: 100%;
  }

  .row-big-container .musikforlag-info {
    width: 100%;
  }

}
