.row-big-container .aterforsaljare-image {
  width: 55%;
}

.row-big-container .aterforsaljare-info {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 950px) {
  .aterfor {
    flex-direction: column;
  }

  .row-big-container .aterforsaljare-image {
    width: 90%;
    margin: auto;
  }

  .row-big-container .aterforsaljare-info {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding-bottom: 30px;
  }

  .spacer-ater {
    display: none;
  }
}
