.toogle-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 55px;
  height: 40px;
  padding: 5px 0;
  box-sizing: border-box;
  align-items: center;

  position: relative;
  float: right;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 4px;
  cursor: pointer;

  margin:20px;
}

.toogle-button:focus {
  outline: none;
}

.toogle-button_line {
  background-color: white;
  display: block;
  width: 30px;
  height: 2px;
  border-radius: 1px;
}
