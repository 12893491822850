.column-container .skola-image {
  width: 35%;
  margin: auto;
}

@media (max-width: 950px) {

  .column-container .skola-image {
    width: 100%;
  }

}
