.row-container .webshop-image {
  width: 50%;
}

.row-container .webshop-info {
  display: flex;
  width: 45%;
  flex-direction: column;
  justify-content: center;
}

.row-container .webshop-info a {
  display: inline;
}

.extra a {
  font-size: 20px;
}

@media (max-width: 950px) {
  .webshop {
    flex-direction: column;
  }

  .row-container .webshop-image {
    width: 100%;
  }

  .row-container .webshop-info {
    width: 100%;
  }
}
