.column-container .radio-image {
  width: 40%;
  margin: auto;
}

.column-container .radio-info {
  width: 80%;
  margin: auto;
}

.column-container .radio-info a {
  display: inline;
}

@media (max-width: 950px) {

  .column-container .radio-info {
    width: 100%;
  }

  .column-container .radio-image {
    width: 100%;
  }

}
