.home-image {
  width: 100%;
}

.home-image .intro-mobile {
  display: none;
}

.home-image .intro-normal {
  width: 100%;
}

@media (max-width: 950px) {

  .home-image .intro-mobile {
    display: block;
    width: 100%;
  }

  .home-image .intro-normal {
    display: none;
  }
}
