.footer-container {
  position: fixed;
  bottom: 0px;
  display: flex;
  align-items: center;
  background-color: green;
  width: 100%;
  margin: 0;
}

.footer-info h4 {
  color: white;
  font-weight: 16px;
  font-weight: bold;
  margin: 5px 0;
}

.footer-info a:hover {
  text-decoration: none;
}

.footer-info ul {
  display: flex;
  list-style: none;
  margin-bottom: 0;
}

.footer-info li {
  padding: 10px;
}

.footer-info li p {
  margin: 0;
}

.loga-wasa {
  height: 50px;
  width: auto;
  padding-right: 50px;
}

.loga-ilka {
  height: 23px;
  width: auto;
  padding-right: 50px;
}

.footer-container .spacer {
  flex: 1;
}

.footer-info ul li .language-menu {
  display: none;
  background-color: white;
  padding: 0;
  color: green;
}

.footer-info ul li:hover .language-menu {
  display: flex;
}

.footer-info ul li .language-menu li:hover{
  background-color: #d7e2d7;
}

@media (max-width: 950px) {

  .footer-container {
    position: static;

  }

  .footer-container {
    display: flex;
    flex-direction: column;
  }

  .footer-info {
    width: 100%;
  }

  .footer-info ul{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-info ul li {
    padding: 0;
    text-align: center;
  }

  .footer-info ul li p{
    padding: 0;
    text-align: center;
  }

  .footer-images {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .loga-wasa {
    padding: 0;
    margin-bottom: 10px;
  }

  .loga-ilka {
    padding: 0;
    margin: 10px;
  }
}
