.access-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

.access-container .access-image-container {
  display: flex;
  justify-content: space-around;
  width: 80%;
  padding-bottom: 20px;
}

.access-container .access-image-container .access-image {
  width: 25%;
}

.access-container .access-image-container .access-image-katalog {
  width: 50%;
  margin: auto;
}

.access-container .access-image-container .access-info {
  width: 40%;
}

.access-container a {
  font-weight: bold;
  font-size: 20px;
  text-decoration: underline;
}

@media (max-width: 950px) {

  .access-container .access-image-container {
    flex-direction: column;
  }

  .access-container .access-image-container .access-image {
    width: 60%;
    margin: auto;
  }

  .access-container .access-image-container .access-image-katalog {
    width: 100%;
    margin: auto;
  }

  .access-container .access-image-container .access-info {
    width: 100%;
    margin: auto;
  }

}
