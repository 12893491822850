


@media (max-width: 950px) {

  .miljo {
    width: 100%;
  }

  .miljo .mijo-info {
    width: 70%;
    margin: auto;
  }

}
