.medverkande-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
  padding: 30px 0;
}

.medverkande-container .medverkande-image {
  width: 40%;
  margin: auto;
}

.medverkande-container .medverkande-info-container {
  display: flex;
}

.medverkande-container .medverkande-info-container .medverkande-info {
  width: 50%;
}

.medverkande-container h3 {
  font-size: 24px;
  color: black;
}

.medverkande-container p {
  margin: 0;
  padding: 0;
}

@media (max-width: 950px) {

  .medverkande-container .medverkande-image {
    width: 100%;
  }

  .medverkande-container .medverkande-info-container {
    flex-direction: column;
  }

  .medverkande-container .medverkande-info-container .medverkande-info {
    width: 100%;
  }
}
