.column-big-container .book-image {
  width: 90%;
  margin: auto;
}

.column-big-container .column-big-info {
  width: 70%;
}

.column-big-container .column-big-info a {
  display: inline;
}
