.row-container .nyheter-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}

.row-container .nyheter-info {
  width: 45%;
}

@media (max-width: 950px) {

  .nyheter {
    flex-direction: column;
  }

  .row-container .nyheter-image {
    width: 100%;
  }

  .row-container .nyheter-info {
    width: 100%;
  }
}
