.produkter-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

.produkter-container .produkter-intro {
  width: 90%;
}

.produkter-container .produkter-intro a {
  font-size: 16px;
}

.produkter-container .produkt-big {
  width: 90%;
}

.produkter-container .produkt-dolls {
  display: flex;
  width: 90%;
}

.produkter-container .produkt-dolls .doll {
  flex: 1;
}

.produkter-container .almanacka {
  display: flex;
  width: 90%;
  justify-content: space-around;
}

.produkter-container .almanacka .almanacka-image {
  width: 50%;
}

.produkter-container .almanacka .almanacka-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
}

.produkter-container .produkt-rest {
  display: flex;
  justify-content: space-around;
  width: 90%;
  padding: 10px 0;
  flex-wrap: wrap;
}

.produkter-container .produkt-rest .rest-item {
  width: 20%;
}

.produkter-container .produkt-rest .rest-item p {
  margin: 0;
}

.produkter-header-mobile {
  display: none;
}

@media (max-width: 950px) {

  .produkter-container .almanacka {
    width: 90%;
    flex-direction: column;
  }

  .produkter-container .almanacka .almanacka-image {
    width: 100%;
  }

  .produkter-container .almanacka .almanacka-info {
    width: 100%;
  }

  .produkter-container .produkt-rest .rest-item {
    width: 45%;
  }

  .produkter-header-normal {
    display: none;
  }

  .produkter-header-mobile {
    display: block;
  }
}
